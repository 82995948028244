@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: 'Helvetica';
    src: url('/public/helveticaNeue/HelveticaNeueThin.otf');
    font-weight: 100;
}
@font-face {
    font-family: 'Helvetica';
    src: url('/public/helveticaNeue/HelveticaNeueLight.otf');
    font-weight: 300;
}

@font-face {
    font-family: 'Helvetica';
    src: url('/public/helveticaNeue/HelveticaNeueRoman.otf');
    font-weight: 400;
}

@font-face {
    font-family: 'Helvetica';
    src: url('/public/helveticaNeue/HelveticaNeueMedium.otf');
    font-weight: 500;
}

@font-face {
    font-family: 'Helvetica';
    src: url('/public/helveticaNeue/HelveticaNeueMedium.otf');
    font-weight: 700;
}

@font-face {
    font-family: 'Helvetica';
    src: url('/public/helveticaNeue/HelveticaNeueBlack.otf');
    font-weight: 900;
}

@layer base {
    html {
        font-size: 10px;
        font-weight: 300;
    }

    a:not(.nav a) {
        @apply text-[#0000FF] underline
    }

    body, p {
        @apply text-[1.6rem] leading-tight lg:leading-relaxed
    }

    h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
        @apply uppercase
    }

    h1, .h1 {
        @apply text-[3.2rem] lg:text-[4.8rem] lg:leading-[76.80px] lg:tracking-wide
    }

    h2, .h2 {
        @apply text-[2.4rem] lg:text-[3.2rem] lg:leading-[51.20px] lg:tracking-wide
    }

    h3, .h3, .subheading {
        @apply text-[1.8rem] lg:text-[2rem] lg:leading-[38.40px]
    }

    h6, .h6 {
        @apply italic text-[1.8rem] lg:text-[2rem]
    }

    .page-width {
        @apply max-w-[calc(100dvw-3rem)] xl:max-w-[1200px] mx-auto lg:px-[5rem]
    }

    .no-spinner {
        -moz-appearance: textfield;
    }

    .no-spinner::-webkit-inner-spin-button,
    .no-spinner::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    .button, .outlineButton {
        @apply
        px-[2.8rem]
        py-6
        rounded-[5px]
    }

    .button {
        @apply
        !text-white
        text-base
        font-bold
        capitalize
        leading-tight
        bg-primary
        border
        border-transparent
        transition-all
        hover:bg-white
        hover:text-primary
        hover:border-primary
    }

    .outlineButton {
        @apply
        text-teal-900
        text-base
        font-bold
        font-['Helvetica']
        capitalize
        leading-tight
    }

    .pageContainer {
        @apply page-width flex flex-col py-12 md:py-16 lg:py-20 gap-12 md:gap-16 lg:gap-20
    }

    .nav {
        @apply font-helveticaLight
    }

    .nav a {
        @apply no-underline transition-all hover:text-primary-red
    }

    .mobileNav > a, .mobileNavAccordion {
        @apply py-4 border-b border-white
    }

    .mobileNav > a:last-child {
        @apply border-transparent
    }

    .slide-in {
        opacity: 0;
        transform: translateX(50px);
        transition: opacity 0.5s ease, transform 0.5s ease;
    }

    .slide-in.active {
        opacity: 1;
        transform: translateY(0);
    }
}